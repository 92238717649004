import React from 'react'
import { graphql } from 'gatsby'
import { Date } from 'prismic-reactjs'
import Layout from '../components/layouts'
import Slices from '../components/slices'

// Query for the Page content in Prismic
export const query = graphql`
query Policy {
  allPrismicPolicy{
    edges{
      node{
        id
        uid
        type
        data {
          title {
            text
            html
            raw
          }
          last_reviewed_at
          body{
            __typename
            ... on PrismicPolicyBodyText {
              slice_type
              slice_label
              items {
                heading {
                  html
                  text
                  raw
                }
                content {
                  html
                  text
                  raw
                }
              }
            }
          }
        }
      }
    }
  }
}
`

// Display the title and content of the Policy
const PolicyBody = ({ policy }) => {
  // Store and format the policy last review date
  let lastReviewedAt = Date(policy.data.last_reviewed_at);
  lastReviewedAt = lastReviewedAt ? 
    new Intl.DateTimeFormat('en-US', {
      month: 'short', 
      day: '2-digit', 
      year: 'numeric'
    }).format(lastReviewedAt) :
    '';

  return (
    <div class="pure-g">
      <div class="pure-u-lg-5-5 pure-u-1-1 main">
        {/* Render the edit button */}
        <h2 data-wio-id={ policy.id }>
          { policy.data.title.text }
        </h2>
        <strong>Last reviewed at: {lastReviewedAt}</strong>
        {/* Go through the slices of the page and render the appropiate one */}
        <Slices slices={ policy.data.body } />
      </div>
    </div>
  );
}

export default (props) => {
  // Define the Page content returned from Prismic
  const doc = props.data.allPrismicPolicy.edges.filter(policy => policy.node.uid === props.pageContext.uid)[0];

  if(!doc) return null;

  return(
    <Layout title={ doc.node.data.title.text }>
      <PolicyBody policy={ doc.node } />
    </Layout>
  )
}
